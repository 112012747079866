// vendors
import React from 'react';
import PropTypes from 'prop-types';
import CustomIntlProvider from './src/contexts/IntlContext';

export const WrapPageElement = ({ element, props }) => {
  const {
    pageContext: { langKey },
  } = props;
  return <CustomIntlProvider locale={langKey}>{element}</CustomIntlProvider>;
};

WrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.shape({
    pageContext: PropTypes.shape({ langKey: PropTypes.string.isRequired })
      .isRequired,
  }).isRequired,
};

export default WrapPageElement;
