import { css } from 'styled-components';
import { em, rem } from 'polished';

import spaces from './spaces';

import breakpoints from './breakpoints';

const box = (color, backgroundColor, borderWidth, space) => {
  return css`
    padding: ${space}rem;

    color: ${color};

    background-color: ${backgroundColor};
    border: ${borderWidth}px solid currentColor;

    > * {
      color: inherit;
    }
  `;
};

const stack = (space) => {
  return css`
    > * + * {
      margin-top: ${space};
    }
  `;
};

const grid = (minWidth, gap, length = 1) => {
  return css`
    /* IE11 fallback */
    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin: calc(${gap} / 2 * -1);

      > * {
        width: calc((100% / ${length}) - ${gap});
        min-width: ${minWidth};
        margin: calc(${gap} / 2);
      }
    }

    /* Modern browsers */
    @supports (grid-gap: 0) {
      display: grid;
      grid-gap: ${gap};
      grid-template-columns: repeat(
        auto-fit,
        minmax(min(${minWidth}, 100%), 1fr)
      );
    }
  `;
};

const center = (space = spaces.sm1, maxWidth = '60ch') => {
  return css`
    box-sizing: content-box;
    max-width: ${maxWidth};
    margin-right: auto;
    margin-left: auto;
    padding-right: ${space}rem;
    padding-left: ${space}rem;
  `;
};

const switcher = (
  limit,
  space = rem(spaces.sm1),
  threshold = em(breakpoints[0])
) => {
  const adjustedSpace = space === 0 ? '0px' : space;

  return css`
    > * {
      display: flex;
      flex-wrap: wrap;
      margin: calc((${adjustedSpace} / 2) * -1);
    }

    > * > * {
      flex-basis: calc((${threshold} - (100% - ${adjustedSpace})) * 999);
      flex-grow: 1;
      margin: calc(${adjustedSpace} / 2);
    }

    > * > :nth-last-child(n + ${limit} + 1),
    > * > :nth-last-child(n + ${limit} + 1) ~ * {
      flex-basis: 100%;
    }
  `;
};

const sidebar = (
  sideWidth,
  side = 'left',
  contentMin = '50%',
  space = rem(spaces.sm1),
  noStretch = false
) => {
  const adjustedSpace = space === 0 ? '0px' : space;

  return css`
    display: flex;
    flex-wrap: wrap;
    align-items: ${noStretch ? 'flex-start' : ''};

    margin: calc(${adjustedSpace} / 2 * -1);

    > * {
      flex-grow: 1;
      ${sideWidth ? `flex-basis: ${sideWidth}` : ''};

      margin: calc(${adjustedSpace} / 2);
    }

    > ${side !== 'left' ? ':first-child' : ':last-child'} {
      flex-basis: 0;
      flex-grow: 999;

      min-width: calc(${contentMin} - ${adjustedSpace});
    }
  `;
};

export default {
  box,
  grid,
  stack,
  center,
  switcher,
  sidebar,
};
