import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from './src/styles/theme';
import CustomWrapPageElement from './wrapPageElement';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);

export const wrapPageElement = CustomWrapPageElement;

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
