// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { IntlProvider } from 'react-intl';

const query = graphql`
  query MyQuery {
    allMessage {
      nodes {
        value
        key
        locale
      }
    }
  }
`;

const CustomIntlProvider = ({ children, locale = 'fr' }) => {
  const data = useStaticQuery(query);

  const messages = data.allMessage.nodes.reduce((acc, cur) => {
    if (!acc[cur.locale]) acc[cur.locale] = {};

    acc[cur.locale][cur.key] = cur.value;

    return acc;
  }, {});

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

CustomIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string.isRequired,
};

export default CustomIntlProvider;
