/**
 * Based on Modular scale
 * from Every Layout https://every-layout.dev/rudiments/modular-scale/
 */
import { lineHeights } from './typography';

const ratio = lineHeights.body; // 1.35
const s0 = 1;
const sm1 = s0 * ratio; // 1.35 (~ 21px)
const sm2 = sm1 * ratio; // 1.8225 (~ 29px)
const sm3 = sm2 * ratio; // 2.460375 (~ 39px)
const sm4 = sm3 * ratio; // 3.32150625 (~ 53px)
const sm5 = sm4 * ratio; // 4.48403344 (~ 72px)
const sm6 = sm5 * ratio; // 6.05344514 (~ 97px)

const sd1 = s0 / ratio; // 0.74074074 (~ 12px)
const sd2 = sd1 / ratio; // 0.54869684 (~ 9px)
const sd3 = sd2 / ratio; // 0.40644211 (~ 7px)
const sd4 = sd3 / ratio; // 0.30106823 (~ 5px)
const sd5 = sd4 / ratio; // 0.2230135 (~ 4px)
const sd6 = sd5 / ratio; // 0.16519519 (~ 3px)

export default {
  ratio,
  s0,
  sm1,
  sm2,
  sm3,
  sm4,
  sm5,
  sm6,
  sd1,
  sd2,
  sd3,
  sd4,
  sd5,
  sd6,
};
