const white = '#ffffff';

const black = '#000000';

const red = '#ff3333';

const turtleGreen = '#77bd43';
const turtleGreen100 = '#add78e';
const turtleGreen200 = '#9fd07b';
const turtleGreen300 = '#92ca68';
const turtleGreen400 = '#84c355';
const turtleGreen500 = '#77bd43';
const turtleGreen600 = '#6baa3c';
const turtleGreen700 = '#5f9735';
const turtleGreen800 = '#53842e';
const turtleGreen900 = '#477128';

const paleOliveGreen = '#a1de73';
const paleOliveGreen100 = '#c6ebab';
const paleOliveGreen200 = '#bde79d';
const paleOliveGreen300 = '#b3e48f';
const paleOliveGreen400 = '#aae181';
const paleOliveGreen500 = '#a1de73';
const paleOliveGreen600 = '#90c767';
const paleOliveGreen700 = '#80b15c';
const paleOliveGreen800 = '#709b50';
const paleOliveGreen900 = '#608545';

const charcoal = '#313130';
const charcoal100 = '#838382';
const charcoal200 = '#6e6e6e';
const charcoal300 = '#5a5a59';
const charcoal400 = '#454544';
const charcoal500 = '#313130';
const charcoal600 = '#2c2c2b';
const charcoal700 = '#272726';
const charcoal800 = '#222221';
const charcoal900 = '#1d1d1c';

const veryLightPink = '#ebebeb';
const veryLightPink100 = '#f3f3f3';
const veryLightPink200 = '#f1f1f1';
const veryLightPink300 = '#efefef';
const veryLightPink400 = '#ededed';
const veryLightPink500 = '#ebebeb';
const veryLightPink600 = '#d3d3d3';
const veryLightPink700 = '#bcbcbc';
const veryLightPink800 = '#a4a4a4';
const veryLightPink900 = '#8d8d8d';

export default {
  white,
  black,
  red,

  turtleGreen,
  turtleGreen100,
  turtleGreen200,
  turtleGreen300,
  turtleGreen400,
  turtleGreen500,
  turtleGreen600,
  turtleGreen700,
  turtleGreen800,
  turtleGreen900,

  paleOliveGreen,
  paleOliveGreen100,
  paleOliveGreen200,
  paleOliveGreen300,
  paleOliveGreen400,
  paleOliveGreen500,
  paleOliveGreen600,
  paleOliveGreen700,
  paleOliveGreen800,
  paleOliveGreen900,

  charcoal,
  charcoal100,
  charcoal200,
  charcoal300,
  charcoal400,
  charcoal500,
  charcoal600,
  charcoal700,
  charcoal800,
  charcoal900,

  veryLightPink,
  veryLightPink100,
  veryLightPink200,
  veryLightPink300,
  veryLightPink400,
  veryLightPink500,
  veryLightPink600,
  veryLightPink700,
  veryLightPink800,
  veryLightPink900,
};
