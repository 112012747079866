export const fontFamilies = {
  body: "'Montserrat', sans-serif",
  heading: 'inherit',
};

export const fontWeights = {
  light: 300,
  medium: 500,
  semibold: 600,
};

export const lineHeights = {
  body: 1.35,
};
